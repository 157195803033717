import split from "lodash/split";
import pick from "lodash/pick";
import commonEn from "./en/common.json";
import commonCs from "./cs/common.json";
import commonRu from "./ru/common.json";

export const SUPPORTED_LANGUAGES = split(
  process.env.REACT_APP_SUPPORTED_LANGUAGES || "en,cs",
  ","
);

const languages = {
  cs: "Čeština",
  en: "English",
  ru: "Русский",
};

export const supportedLanguages = pick(languages, SUPPORTED_LANGUAGES);

/**
 * These are the translation resources to be use
 */
export const resources = {
  en: {
    common: commonEn, // 'common' is our custom namespace
  },
  cs: {
    common: commonCs, // 'common' is our custom namespace
  },
  ru: {
    common: commonRu, // 'common' is our custom namespace
  },
};
