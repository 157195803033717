import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import first from "lodash/first";
import { PageSettings } from "../../config/page-settings";
import { useAuth } from "../../Auth";
import UserAvatar from "../UserAvatar";

const SidebarProfile = () => {
  const { user } = useAuth();
  const [profileActive, setProfileActive] = useState(0);

  const handleProfileExpand = useCallback(() => {
    setProfileActive(!profileActive);
  }, [profileActive, setProfileActive]);

  return (
    <PageSettings.Consumer>
      {({ pageSidebarMinify }) => (
        <ul className="nav">
          <li className={`nav-profile ${profileActive ? "expand " : ""}`}>
            <Link to="/" onClick={handleProfileExpand}>
              <div className="cover with-shadow" />
              <div className="image">
                <UserAvatar name={user} />
              </div>
              <div className="info">
                {first(String(user).split("@"))}
                <small />
              </div>
            </Link>
          </li>
        </ul>
      )}
    </PageSettings.Consumer>
  );
};

export default SidebarProfile;

// class SidebarProfile extends React.Component {

// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			profileActive: 0
// 		};
// 		this.handleProfileExpand = this.handleProfileExpand.bind(this);
// 	}

// 	handleProfileExpand(e) {
// 		e.preventDefault();
// 		this.setState(state => ({
// 			profileActive: !this.state.profileActive,
// 		}));
// 	}

// 	render() {
// 		return (
//   <PageSettings.Consumer>
//     {({pageSidebarMinify}) => (
//       <ul className="nav">
//         <li className={`nav-profile ${  this.state.profileActive ? "expand " : ""}`}>
//           <Link to="/" onClick={this.handleProfileExpand}>
//             <div className="cover with-shadow" />
//             <div className="image">
//               <img src={UserImage} alt="" />
//             </div>
//             <div className="info">
//               <b className="caret pull-right" />
//               Sergey Mayzus
//               <small />
//             </div>
//           </Link>
//         </li>
//         <li>
//           <ul className={`nav nav-profile ${  this.state.profileActive && !pageSidebarMinify ? "d-block " : ""}`}>
//             <li>
//               <Link to="/">
//                 <i className="fa fa-cog" />
//                 {' '}
//                 Settings
//               </Link>
//             </li>
//             <li>
//               <Link to="/">
//                 <i className="fa fa-pencil-alt" />
//                 {' '}
//                 Send Feedback
//               </Link>
//             </li>
//             <li>
//               <Link to="/">
//                 <i className="fa fa-question-circle" />
//                 {' '}
//                 Helps
//               </Link>
//             </li>
//           </ul>
//         </li>
//       </ul>
// 				)}
//   </PageSettings.Consumer>
// 		)
// 	}
// }

// export default SidebarProfile;
