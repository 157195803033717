import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
// import DropdownNotification from './dropdown/notification';
import styled from "styled-components";
import DropdownLanguage from "./dropdown/language";
import DropdownProfile from "./dropdown/DropdownProfile";
// import SearchForm from './search/form';
import DropdownMegaMenu from "./dropdown/mega";
import BrandLogo from "../../assets/img/brand-logo.svg";
import variables from "../../scss/_variables.scss";

import { PageSettings } from "../../config/page-settings";

const StyledHeader = styled("div")(() => ({
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",

  "&.navbar-default": {
    background: variables.contentBackground,
  },
}));

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.toggleMegaMenu = this.toggleMegaMenu.bind(this);
    this.state = { collapseMegaMenu: false };
  }

  toggleMegaMenu() {
    this.setState({ collapseMegaMenu: !this.state.collapseMegaMenu });
  }

  render() {
    const { className } = this.props;

    return (
      <PageSettings.Consumer>
        {({
          toggleMobileSidebar,
          toggleRightSidebar,
          toggleMobileRightSidebar,
          toggleMobileTopMenu,
          pageHeaderLanguageBar,
          pageHeaderMegaMenu,
          pageTwoSidebar,
          pageTopMenu,
          pageSidebar,
        }) => (
          <StyledHeader
            id="header"
            className={classNames("header navbar-default", className)}
          >
            <div className="navbar-header">
              {pageTwoSidebar && (
                <button
                  type="button"
                  className="navbar-toggle pull-left"
                  onClick={toggleMobileRightSidebar}
                >
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                </button>
              )}
              <Link to="/" className="navbar-brand brand-logo">
                <img src={BrandLogo} alt="brand logo" height={30} />
                {/* eslint-disable-next-line react/jsx-no-literals */}
                <span className="version ml-1">{`v${process.env.REACT_APP_VERSION}`}</span>
              </Link>

              {pageHeaderMegaMenu && (
                <button
                  type="button"
                  className="navbar-toggle pt-0 pb-0 mr-0"
                  onClick={this.toggleMegaMenu}
                >
                  <span className="fa-stack fa-lg text-inverse">
                    <i className="far fa-square fa-stack-2x" />
                    <i className="fa fa-cog fa-stack-1x" />
                  </span>
                </button>
              )}
              {pageTopMenu && pageSidebar && (
                <button
                  type="button"
                  className="navbar-toggle pt-0 pb-0 mr-0 collapsed"
                  onClick={toggleMobileTopMenu}
                >
                  <span className="fa-stack fa-lg text-inverse">
                    <i className="far fa-square fa-stack-2x" />
                    <i className="fa fa-cog fa-stack-1x" />
                  </span>
                </button>
              )}
              {!pageSidebar && pageTopMenu && (
                <button
                  type="button"
                  className="navbar-toggle"
                  onClick={toggleMobileTopMenu}
                >
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                </button>
              )}
              {pageSidebar && (
                <button
                  type="button"
                  className="navbar-toggle"
                  onClick={toggleMobileSidebar}
                >
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                </button>
              )}
            </div>

            {pageHeaderMegaMenu && (
              <DropdownMegaMenu collapse={this.state.collapseMegaMenu} />
            )}

            <ul className="navbar-nav navbar-right">
              {/* <SearchForm /> */}
              {/* <DropdownNotification /> */}

              {pageHeaderLanguageBar && <DropdownLanguage />}

              <DropdownProfile />

              {pageTwoSidebar && <li className="divider d-none d-md-block" />}

              {pageTwoSidebar && (
                <li className="d-none d-md-block">
                  <Link to="/" onClick={toggleRightSidebar} className="f-s-14">
                    <i className="fa fa-th" />
                  </Link>
                </li>
              )}
            </ul>
          </StyledHeader>
        )}
      </PageSettings.Consumer>
    );
  }
}

export default Header;
