import { defaultTo, get } from "lodash";
import React, { useState, useContext, createContext } from "react";

export const AUTH_STORAGE_KEY = "bo-auth";

const authContext = createContext({
  user: "",
  setUser: () => {},
  token: "",
  setToken: () => {},
  isAdmin: "",
  setIsAdmin: () => {},
  isManager: "",
  setIsManager: () => {},
  isService: "",
  setIsService: () => {},
});

export const useAuthContext = () => {
  return useContext(authContext);
};

export const getAuthFromStorage = () => {
  return JSON.parse(
    defaultTo(
      localStorage.getItem(AUTH_STORAGE_KEY),
      '{"user":"","token":"","is_admin":false,"is_manager":false,"is_service":false}'
    )
  );
};

export const useProvideAuth = () => {
  const persistentAuth = getAuthFromStorage();
  const [user, setUser] = useState(defaultTo(persistentAuth.user, ""));
  const [token, setToken] = useState(defaultTo(persistentAuth.token, ""));
  const [isAdmin, setIsAdmin] = useState(
    defaultTo(persistentAuth.is_admin, false)
  );
  const [isManager, setIsManager] = useState(
    defaultTo(persistentAuth.is_manager, false)
  );
  const [isService, setIsService] = useState(
    defaultTo(persistentAuth.is_service, false)
  );

  const setAuthDetails = (apiResponseData) => {
    setToken(get(apiResponseData, "token"));
    setIsAdmin(get(apiResponseData, "is_admin"));
    setIsManager(get(apiResponseData, "is_manager"));
    setIsService(get(apiResponseData, "is_service"));
    setUser(get(apiResponseData, "user"));
    localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(apiResponseData));
  };

  return {
    user,
    setUser,
    token,
    setToken,
    isAdmin,
    setIsAdmin,
    isManager,
    setIsManager,
    isService,
    setIsService,
    setAuthDetails,
  };
};

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};
