import Loader from "../components/LoadingComponent";

export const AsyncLogin = Loader({
  loader: () => import("../containers/Login"),
});

export const AsyncMap = Loader({
  loader: () => import("../containers/Map"),
});

export const AsyncDetails = Loader({
  loader: () => import("../containers/Details"),
});

export const AsyncCardRequestLogs = Loader({
  loader: () => import("../containers/CardRequestLogs"),
});

export const AsyncEvents = Loader({
  loader: () => import("../containers/Events"),
});

export const AsyncAccounts = Loader({
  loader: () => import("../containers/Accounts"),
});

export const AsyncProfile = Loader({
  loader: () => import("../containers/Profile"),
});

export const AsyncWithdrawals = Loader({
  loader: () => import("../containers/Withdrawals"),
});

export const AsyncDeposits = Loader({
  loader: () => import("../containers/Deposits"),
});

export const AsyncTerminals = Loader({
  loader: () => import("../containers/Terminals"),
});

export const AsyncNotifications = Loader({
  loader: () => import("../containers/Notifications"),
});

export const AsyncOtherEvents = Loader({
  loader: () => import("../containers/OtherEvents"),
});
