/**
 * API related contexts
 */

import { defaultTo } from "lodash";
import React, { useContext, useState, useCallback } from "react";
import { CacheProvider } from "./cacheContext";

const STORAGE_KEY_TERMINALS = "terminals";

export const ApiContext = React.createContext({
  terminals: false,
  setTerminals: () => {},
  accounts: [],
  setAccounts: () => {},
  selectedAccount: {},
  setSelectedAccount: () => {},
  selectedAccountBalance: {},
  setSelectedAccountBalance: () => {},
  notifications: {},
  setNotifications: () => {},
  unreadNotificationCount: 0,
  setUnreadNotificationCount: () => {},
});

ApiContext.displayName = "Api";

export const useApiContext = () => useContext(ApiContext); // to be called in components

export const ApiProvider = ({ children }) => {
  const persistentTerminals = JSON.parse(
    defaultTo(sessionStorage.getItem(STORAGE_KEY_TERMINALS), "[]")
  );

  const [terminals, setTerminalsRaw] = useState(persistentTerminals);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccountBalance, setSelectedAccountBalance] = useState({});
  const [selectedAccount, setSelectedAccount] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

  const setTerminals = useCallback(
    (val) => {
      setTerminalsRaw(val);
      sessionStorage.setItem(STORAGE_KEY_TERMINALS, JSON.stringify(val));
    },
    [setTerminalsRaw]
  );

  return (
    <ApiContext.Provider
      value={{
        terminals,
        setTerminals,
        accounts,
        setAccounts,
        selectedAccount,
        setSelectedAccount,
        selectedAccountBalance,
        setSelectedAccountBalance,
        notifications,
        setNotifications,
        unreadNotificationCount,
        setUnreadNotificationCount,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export const AppContextsProvider = ({ children }) => (
  <CacheProvider>
    <ApiProvider>{children}</ApiProvider>
  </CacheProvider>
);
