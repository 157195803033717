import React, { useEffect } from "react";
import { isEmpty, get } from "lodash";
import { useHistory } from "react-router-dom";
import { useAuth } from "../Auth";
import { urlPaths } from "./urls";

export const requireAuthentication = (Component) => (props) => {
  const { user } = useAuth();
  const { push, location } = useHistory();

  useEffect(() => {
    if (isEmpty(user)) {
      const pathname = get(location, "pathname");
      push(`${urlPaths.login}/next=${pathname}`);
    }
  }, [user, push, location]);

  return <Component {...props} />;
};
