import { noop } from 'lodash';
import { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { POST } from './api/http';
import { ENDPOINTS } from './config/endpoints';
import { useProvideAuth } from './contexts/contextAuth';
import { urlPaths } from './router/urls';

export const useAuth = () => {
  const { push } = useHistory();
  const { token, setAuthDetails, user } = useProvideAuth();
  const [loginErrors, setLoginErrors] = useState({});

  /**
   * Error Format:
   * {
   *    Field: ["Messages"]
   * }
   */
  const handleCommonError = useCallback((error) => {
    setLoginErrors(error);
  }, []);

  const login = useCallback(
    async ({ username, password }, onError = noop) => {
      try {
        const response = await POST(ENDPOINTS.LOGIN, { username, password }, onError);
        console.log(`login response`, response);
        setAuthDetails({ ...response, user: username });
      } catch (error) {
        console.log(error);
        handleCommonError(error);
      }
    },
    [handleCommonError, setAuthDetails],
  );

  const logout = () => {
    setAuthDetails('{}');
    push(urlPaths.login);
  };

  return {
    loginErrors,
    user,
    login,
    logout,
    token,
  };
};
