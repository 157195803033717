import React from "react";
import { Route, Switch } from "react-router";
import { compose } from "recompose";
import {
  AsyncLogin,
  AsyncMap,
  AsyncDetails,
  AsyncCardRequestLogs,
  AsyncEvents,
  AsyncAccounts,
  AsyncProfile,
  AsyncWithdrawals,
  AsyncDeposits,
  AsyncTerminals,
  AsyncNotifications,
  AsyncOtherEvents,
} from "./asyncLoaders";
import { requireAuthentication } from "./requireAuthentication";
import { urlPaths, urlPathsWithParams } from "./urls";

const LoginPage = compose()(AsyncLogin);
const MapPage = compose(requireAuthentication)(AsyncMap);
const DetailsPage = compose(requireAuthentication)(AsyncDetails);
const CardRequestLogsPage = compose(requireAuthentication)(
  AsyncCardRequestLogs
);
const EventsPage = compose(requireAuthentication)(AsyncEvents);
const AccountsPage = compose()(AsyncAccounts);
const ProfilePage = compose()(AsyncProfile);

// ----------------ROUTES----------------//

const Routes = (() => (
  <div id="root-start">
    <Switch>
      <Route exact path={urlPaths.home} component={MapPage} />
      <Route path={urlPaths.login} component={LoginPage} />
      <Route path={urlPathsWithParams.details} component={DetailsPage} />
      <Route path={urlPaths.cardLogs} component={CardRequestLogsPage} />
      <Route path={urlPathsWithParams.events} component={EventsPage} />
      <Route path={urlPaths.accounts} component={AccountsPage} />
      <Route path={urlPathsWithParams.profile} component={ProfilePage} />
      <Route path={urlPaths.withdrawals} component={AsyncWithdrawals} />
      <Route path={urlPaths.deposits} component={AsyncDeposits} />
      <Route path={urlPaths.terminals} component={AsyncTerminals} />
      <Route path={urlPaths.notifications} component={AsyncNotifications} />
      <Route path={urlPaths.otherEvents} component={AsyncOtherEvents} />
    </Switch>
  </div>
))();

export default Routes;
