import i18n from 'i18next';
import get from 'lodash/get';
import indexOf from 'lodash/indexOf';
import size from 'lodash/size';
import { initReactI18next, useTranslation } from 'react-i18next';
import { useMemo, useCallback, useContext } from 'react';
import { resources, supportedLanguages, SUPPORTED_LANGUAGES } from './locales';
import { LanguageContext } from './contexts';

export const currentLanguageStore =
  window.localStorage.getItem('language') || process.env.REACT_APP_DEFAULT_LANGUAGE || 'en';

export const useLocalize = (namespace = 'common') => {
  const { t, i18n: locI18n } = useTranslation(namespace);
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);

  const changeLanguage = useCallback(
    (lang) => {
      setCurrentLanguage(lang);
      window.localStorage.setItem('language', lang);
      locI18n.changeLanguage(lang);
    },
    [setCurrentLanguage, locI18n],
  );

  /**
   * Get next language in supported language collection
   *
   * i.e. supported language = { en: 'English', cs: 'Čeština' }
   * current: 'en'
   * next: 'cs'
   */
  const nextLanguage = useMemo(() => {
    const currentIndex = indexOf(SUPPORTED_LANGUAGES, currentLanguage);
    const nextIndex = currentIndex + 1;
    const sizeZeroBased = size(SUPPORTED_LANGUAGES) - 1;
    if (nextIndex <= sizeZeroBased) {
      const key = get(SUPPORTED_LANGUAGES, nextIndex);
      return {
        key,
        name: get(supportedLanguages, key),
      };
    }

    const key = get(SUPPORTED_LANGUAGES, 0);
    return {
      key,
      name: get(supportedLanguages, key),
    };
  }, [currentLanguage]);

  /**
   * Toggle current language based on supported languages order
   * 1st toggle will select first index, 2nd will select 2nd index and so on...
   */
  const toggleLanguage = useCallback(() => changeLanguage(nextLanguage.key), [
    nextLanguage,
    changeLanguage,
  ]);

  const currentLanguageName = useMemo(() => get(supportedLanguages, currentLanguage), [
    currentLanguage,
  ]);

  return {
    gettext: t,
    changeLanguage,
    currentLanguage,
    currentLanguageName,
    nextLanguage,
    toggleLanguage,
    supportedLanguages,
  };
};

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    lng: currentLanguageStore,
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
    debug: false,
    saveMissing: true,
    referenceLng: "en",
    returnEmptyString: false,
    keySeparator: false,
    nsSeparator: false,
    defaultNS: "common",
    resources,
  });

export default i18n;
