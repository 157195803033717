import React from 'react';
import Loader from 'react-loadable';
import CustomLoader from './CustomLoader';
import "./CustomLoader.scss";

/**
 * Loading component for pages
 *
 * @typedef {Object} DefaultLoadingComponentProps
 * @property {boolean} error - if page loading encounter error
 * @property {boolean} timedOut - if page loading timeout
 * @property {boolean} pastDelay - if page is loading
 * @property {function} retry - retry page load
 * @property {Object<string, string>} classes - class names derived from component styles object
 * @param {DefaultLoadingComponentProps} props
 */
const DefaultLoadingComponent = () => {
  
  return (
    <div className="LoadingContainer">
      <CustomLoader isLoading message="" />
    </div>
  );
};

/**
 * We have to use withStyles instead of makeStyles hook so default component won't load all
 * makeStyles or styles imported via hook even before library styles are loaded
 */
const Loadable = (options) =>
  Loader({
    loading: DefaultLoadingComponent,
    delay: 300,
    timeout: 20000,
    ...options,
  });

export default Loadable;
