import React, { useState, useCallback } from 'react';
import { Route, Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { PageSettings } from "../../config/page-settings";

const SidebarNavList = ({ data, active, clicked, expand }) => {
  const { push } = useHistory();
  const [localActive, setLocalActive] = useState(-1);
  const [localClicked, setLocalClicked] = useState(-1);

  const handleExpand = (e, i, match) => {
    if (e) e.preventDefault();

    setLocalActive(localActive === i ? -1 : i);
    setLocalClicked(1);
  };

  const handleRedirect = useCallback(
    (url) => {
      push(url);
    },
    [push],
  )

  const icon = data.icon && <i className={data.icon} />;
  const img = data.img && (
    <div className="icon-img">
      <img src={data.img} alt="" />
    </div>
  );
  const caret = data.children && !data.badge && <b className="caret" />;
  const label = data.label && <span className="label label-theme m-l-5">{data.label}</span>;
  const badge = data.badge && <span className="badge pull-right">{data.badge}</span>;
  const title = data.title && (
    <span>
      {data.title}
      {' '}
      {label}
    </span>
  );
  const highlight = data.highlight && <i className="fa fa-paper-plane text-theme" />;

  return (
    <PageSettings.Consumer>
      {({ handleSidebarOnMouseOver, handleSidebarOnMouseOut, pageSidebarMinified }) => (
        <Route
          path={data.path}
          exact={data.exact}
          // eslint-disable-next-line react/no-children-prop
          children={({ match }) => (
            <li
              className={classNames({
                active: match && match.isExact,
                expand: active || (clicked === -1 && match) || data.search,
                closed: !(active || (clicked === -1 && match) || data.search),
                'has-sub': data.children
              })}
            >
              {data.children ? (
                <Link
                  to="#"
                  onMouseOver={(e) => handleSidebarOnMouseOver(e, data)}
                  onMouseOut={(e) => handleSidebarOnMouseOut(e, data)}
                  onClick={() => {
                    expand();
                    handleRedirect(data.path)
                  }}
                >
                  {caret}
                  {' '}
                  {badge}
                  {' '}
                  {img}
                  {' '}
                  {icon}
                  {' '}
                  {title}
                  {' '}
                  {highlight}
                </Link>
              ) : (
                <Link to={data.path}>
                  {caret}
                  {' '}
                  {img}
                  {' '}
                  {icon}
                  {' '}
                  {badge}
                  {' '}
                  {title}
                  {' '}
                  {highlight}
                </Link>
              )}
              {data.children && (
                <ul
                  className={
                    `sub-menu ${(active || (clicked === -1 && match) || data.search) &&
                      !pageSidebarMinified
                      ? 'd-block '
                      : 'd-none'}`
                  }
                >
                  {data.children &&
                    data.children.map((submenu, i) => (
                      <SidebarNavList
                        data={submenu}
                        key={i}
                        expand={(e) => handleExpand(e, i, match)}
                        active={i === localActive}
                        clicked={localClicked}
                      />
                    ))}
                </ul>
              )}
            </li>
          )}
        />
      )}
    </PageSettings.Consumer>
  );
};

export default SidebarNavList;
