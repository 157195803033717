import React, { useState, useCallback } from 'react';
import { Route } from 'react-router-dom';
import { map, size } from 'lodash';
import SidebarNavList from './sidebar-nav-list';
import initialMenus from './menu';
import { useApiContext } from '../../contexts/contextApi';
import { urlPaths } from '../../router/urls';

const createSubLinksForTerminal = (id) => [
  // { path: `${urlPaths.cardLogs}`, title: 'Card Logs', icon: 'fa fa-archive' },
  { path: `${urlPaths.events}/${id}`, title: 'Events', icon: 'fa fa-flag' },
  { path: `${urlPaths.details}/${id}`, title: 'Sensors', icon: 'fa fa-wifi' },
];

const SidebarNav = () => {
  const { terminals, unreadNotificationCount } = useApiContext();
  const [active, setActive] = useState(-1);
  const [clicked, setClicked] = useState(-1);

  const handleExpand = useCallback(
    (e, i, match) => {
      // e.preventDefault();
      if (clicked === -1 && match) {
        setActive(-1);
        setClicked(1);
      } else {
        setActive(active === i ? -1 : i);
        setClicked(1);
      }
    },
    [active, setActive, setClicked, clicked],
  );

  /**
   * Add terminals to sidebar for easy access of card logs, events and sensors
   */
  const menusWithMachine = (() =>
    map(initialMenus, (item) => {
      if (item.title === 'Machines' && size(terminals) > 0) {
        return {
          ...item,
          children: map(terminals, (t) => ({
            path: '#',
            title: t.name,
            children: createSubLinksForTerminal(t.id),
          })),
        };
      }
      // Add new notification count
      if (item.title === 'Notifications') {
        return { ...item, badge: unreadNotificationCount || null };
      }
      return item;
    }))();

  return (
    <ul className="nav sidebar-nav">
      {menusWithMachine.map((menu, i) => (
        <Route
          path={menu.path}
          exact={menu.exact}
          // eslint-disable-next-line react/jsx-no-literals, react/no-array-index-key
          key={`${i}-${menu.title}`}
          // eslint-disable-next-line react/no-children-prop
          children={({ match }) => (
            <SidebarNavList
              data={menu}
              // eslint-disable-next-line react/jsx-no-literals, react/no-array-index-key
              key={`${i}-${menu.title}-child`}
              expand={(e) => handleExpand(e, i, match)}
              active={i === active}
              clicked={clicked}
            />
          )}
        />
      ))}
    </ul>
  );
};

export default SidebarNav;
