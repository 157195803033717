/* eslint-disable react/jsx-no-literals */
import React from 'react';
import classNames from 'classnames';
import { PageSettings } from './config/page-settings';

import Header from './components/header/header';
import Sidebar from './components/sidebar/sidebar';
import SidebarRight from './components/sidebar-right/sidebar-right';
import TopMenu from './components/top-menu/top-menu';
import Content from './components/content/content';
import Footer from './components/footer/footer';
import FloatSubMenu from './components/float-sub-menu/float-sub-menu';
import { AppContextsProvider } from './contexts/contextApi';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.toggleSidebarMinify = (e) => {
      e.preventDefault();
      const { pageSidebarMinify } = this.state;
      if (pageSidebarMinify) {
        this.setState(() => ({
          pageFloatSubMenuActive: false,
        }));
      }
      this.setState(() => ({
        pageSidebarMinify: !pageSidebarMinify,
      }));
    };
    this.toggleMobileSidebar = () => {
      const { pageSidebarToggled } = this.state;
      this.setState(() => ({
        pageSidebarToggled: !pageSidebarToggled,
      }));
    };
    this.handleSetPageSidebar = (value) => {
      this.setState(() => ({
        pageSidebar: value,
      }));
    };
    this.handleSetPageSidebarMinified = (value) => {
      this.setState(() => ({
        pageSidebarMinify: value,
      }));
    };
    this.handleSetPageSidebarWide = (value) => {
      this.setState(() => ({
        pageSidebarWide: value,
      }));
    };
    this.handleSetPageSidebarLight = (value) => {
      this.setState(() => ({
        pageSidebarLight: value,
      }));
    };
    this.handleSetPageSidebarTransparent = (value) => {
      this.setState(() => ({
        pageSidebarTransparent: value,
      }));
    };
    this.handleSetPageSidebarSearch = (value) => {
      this.setState(() => ({
        pageSidebarSearch: value,
      }));
    };

    this.toggleRightSidebar = (e) => {
      e.preventDefault();
      const { pageRightSidebarCollapsed } = this.state;
      this.setState(() => ({
        pageRightSidebarCollapsed: !pageRightSidebarCollapsed,
      }));
    };
    this.toggleMobileRightSidebar = (e) => {
      e.preventDefault();
      const { pageMobileRightSidebarToggled } = this.state;
      this.setState(() => ({
        pageMobileRightSidebarToggled: !pageMobileRightSidebarToggled,
      }));
    };
    this.handleSetPageRightSidebar = (value) => {
      this.setState(() => ({
        pageRightSidebar: value,
      }));
    };

    let floatSubMenuRemove;
    let floatSubMenuCalculate;
    const floatSubMenuRemoveTime = 250;
    this.handleFloatSubMenuOnMouseOver = (e) => {
      clearTimeout(floatSubMenuRemove);
      clearTimeout(floatSubMenuCalculate);
    };
    this.handleFloatSubMenuOnMouseOut = (e) => {
      floatSubMenuRemove = setTimeout(() => {
        this.setState(() => ({
          pageFloatSubMenuActive: false,
        }));
      }, floatSubMenuRemoveTime);
    };
    this.handleSidebarOnMouseOver = (e, menu) => {
      if (this.state.pageSidebarMinify) {
        if (menu.children) {
          const left = `${
            document.getElementById('sidebar').offsetWidth + document.getElementById('sidebar').offsetLeft
          }px`;

          clearTimeout(floatSubMenuRemove);
          clearTimeout(floatSubMenuCalculate);

          this.setState(() => ({
            pageFloatSubMenu: menu,
            pageFloatSubMenuActive: true,
            pageFloatSubMenuLeft: left,
          }));

          const offset = e.currentTarget.offsetParent.getBoundingClientRect();

          floatSubMenuCalculate = setTimeout(() => {
            const targetTop = offset.top;
            const windowHeight = window.innerHeight;
            const targetHeight = document.querySelector('.float-sub-menu-container').offsetHeight;
            let top;
            let bottom;
            let arrowTop;
            let arrowBottom;
            let lineTop;
            let lineBottom;

            if (windowHeight - targetTop > targetHeight) {
              top = `${offset.top}px`;
              bottom = 'auto';
              arrowTop = '20px';
              arrowBottom = 'auto';
              lineTop = '20px';
              lineBottom = 'auto';
            } else {
              const aBottom = windowHeight - targetTop - 21;
              top = 'auto';
              bottom = '0';
              arrowTop = 'auto';
              arrowBottom = `${aBottom}px`;
              lineTop = '20px';
              lineBottom = `${aBottom}px`;
            }

            this.setState((state) => ({
              pageFloatSubMenuTop: top,
              pageFloatSubMenuBottom: bottom,
              pageFloatSubMenuLineTop: lineTop,
              pageFloatSubMenuLineBottom: lineBottom,
              pageFloatSubMenuArrowTop: arrowTop,
              pageFloatSubMenuArrowBottom: arrowBottom,
              pageFloatSubMenuOffset: offset,
            }));
          }, 0);
        } else {
          floatSubMenuRemove = setTimeout(() => {
            this.setState((state) => ({
              pageFloatSubMenu: '',
              pageFloatSubMenuActive: false,
            }));
          }, floatSubMenuRemoveTime);
        }
      }
    };
    this.handleSidebarOnMouseOut = (e) => {
      if (this.state.pageSidebarMinify) {
        floatSubMenuRemove = setTimeout(() => {
          this.setState(() => ({
            pageFloatSubMenuActive: false,
          }));
        }, floatSubMenuRemoveTime);
      }
    };
    this.handleFloatSubMenuClick = () => {
      const { pageSidebarMinify, pageFloatSubMenuOffset } = this.state;
      if (pageSidebarMinify) {
        const windowHeight = window.innerHeight;
        const targetHeight = document.getElementById('float-sub-menu').offsetHeight;
        const targetTop = pageFloatSubMenuOffset.top;
        const top = windowHeight - targetTop > targetHeight ? targetTop : 'auto';
        const left = `${pageFloatSubMenuOffset.left + document.getElementById('sidebar').offsetWidth}px`;
        const bottom = windowHeight - targetTop > targetHeight ? 'auto' : '0';
        const arrowTop = windowHeight - targetTop > targetHeight ? '20px' : 'auto';
        const arrowBottom = windowHeight - targetTop > targetHeight ? 'auto' : `${windowHeight - targetTop - 21}px`;
        const lineTop = windowHeight - targetTop > targetHeight ? '20px' : 'auto';
        const lineBottom = windowHeight - targetTop > targetHeight ? 'auto' : `${windowHeight - targetTop - 21}px`;

        this.setState((state) => ({
          pageFloatSubMenuTop: top,
          pageFloatSubMenuLeft: left,
          pageFloatSubMenuBottom: bottom,
          pageFloatSubMenuLineTop: lineTop,
          pageFloatSubMenuLineBottom: lineBottom,
          pageFloatSubMenuArrowTop: arrowTop,
          pageFloatSubMenuArrowBottom: arrowBottom,
        }));
      }
    };

    this.handleSetPageContent = (value) => {
      this.setState(() => ({
        pageContent: value,
      }));
    };
    this.handleSetPageContentClass = (value) => {
      this.setState(() => ({
        pageContentClass: value,
      }));
    };
    this.handleSetPageContentFullHeight = (value) => {
      this.setState(() => ({
        pageContentFullHeight: value,
      }));
    };
    this.handleSetPageContentFullWidth = (value) => {
      this.setState(() => ({
        pageContentFullWidth: value,
      }));
    };
    this.handleSetPageContentInverseMode = (value) => {
      this.setState(() => ({
        pageContentInverseMode: value,
      }));
    };

    this.handleSetPageHeader = (value) => {
      this.setState(() => ({
        pageHeader: value,
      }));
    };
    this.handleSetPageHeaderMegaMenu = (value) => {
      this.setState(() => ({
        pageHeaderMegaMenu: value,
      }));
    };
    this.handleSetPageHeaderLanguageBar = (value) => {
      this.setState(() => ({
        pageHeaderLanguageBar: value,
      }));
    };

    this.handleSetPageFooter = (value) => {
      this.setState(() => ({
        pageFooter: value,
      }));
    };
    this.handleSetPageTopMenu = (value) => {
      this.setState(() => ({
        pageTopMenu: value,
      }));
    };
    this.toggleMobileTopMenu = (e) => {
      e.preventDefault();
      this.setState(() => ({
        pageMobileTopMenu: !this.state.pageMobileTopMenu,
      }));
    };
    this.handleSetPageTwoSidebar = (value) => {
      this.setState(() => ({
        pageTwoSidebar: value,
      }));
    };
    this.handleSetPageBoxedLayout = (value) => {
      if (value === true) {
        document.body.classList.add('boxed-layout');
      } else {
        document.body.classList.remove('boxed-layout');
      }
    };
    this.handleSetBodyWhiteBg = (value) => {
      if (value === true) {
        document.body.classList.add('bg-white');
      } else {
        document.body.classList.remove('bg-white');
      }
    };

    this.state = {
      pageHeader: true,
      pageheaderMegaMenu: false,
      pageHeaderLanguageBar: false,
      hasScroll: false,
      handleSetPageHeader: this.handleSetPageHeader,
      handleSetPageHeaderLanguageBar: this.handleSetPageHeaderLanguageBar,
      handleSetPageHeaderMegaMenu: this.handleSetPageHeaderMegaMenu,

      pageSidebar: true,
      pageSidebarWide: false,
      pageSidebarLight: false,
      pageSidebarMinify: false,
      pageSidebarToggled: false,
      pageSidebarTransparent: false,
      pageSidebarSearch: false,
      handleSetPageSidebar: this.handleSetPageSidebar,
      handleSetPageSidebarWide: this.handleSetPageSidebarWide,
      handleSetPageSidebarLight: this.handleSetPageSidebarLight,
      handleSetPageSidebarMinified: this.handleSetPageSidebarMinified,
      handleSetPageSidebarTransparent: this.handleSetPageSidebarTransparent,
      handleSetPageSidebarSearch: this.handleSetPageSidebarSearch,
      handleSidebarOnMouseOut: this.handleSidebarOnMouseOut,
      handleSidebarOnMouseOver: this.handleSidebarOnMouseOver,
      toggleSidebarMinify: this.toggleSidebarMinify,
      toggleMobileSidebar: this.toggleMobileSidebar,

      pageFloatSubMenuActive: false,
      pageFloatSubMenu: '',
      pageFloatSubMenuTop: 'auto',
      pageFloatSubMenuLeft: 'auto',
      pageFloatSubMenuBottom: 'auto',
      pageFloatSubMenuLineTop: 'auto',
      pageFloatSubMenuLineBottom: 'auto',
      pageFloatSubMenuArrowTop: 'auto',
      pageFloatSubMenuArrowBottom: 'auto',
      pageFloatSubMenuOffset: '',
      handleFloatSubMenuOnMouseOver: this.handleFloatSubMenuOnMouseOver,
      handleFloatSubMenuOnMouseOut: this.handleFloatSubMenuOnMouseOut,
      handleFloatSubMenuClick: this.handleFloatSubMenuClick,

      pageContent: true,
      pageContentClass: '',
      pageContentFullHeight: false,
      pageContentFullWidth: false,
      pageContentInverseMode: false,
      handleSetPageContent: this.handleSetPageContent,
      handleSetPageContentClass: this.handleSetPageContentClass,
      handleSetPageContentFullHeight: this.handleSetPageContentFullHeight,
      handleSetPageContentFullWidth: this.handleSetPageContentFullWidth,
      handleSetPageContentInverseMode: this.handleSetPageContentInverseMode,

      pageFooter: false,
      handleSetPageFooter: this.handleSetPageFooter,

      pageTopMenu: false,
      pageMobileTopMenu: false,
      toggleMobileTopMenu: this.toggleMobileTopMenu,
      handleSetPageTopMenu: this.handleSetPageTopMenu,

      pageTwoSidebar: false,
      handleSetPageTwoSidebar: this.handleSetPageTwoSidebar,

      pageRightSidebar: false,
      pageRightSidebarToggled: true,
      pageMobileRightSidebarToggled: false,
      toggleRightSidebar: this.toggleRightSidebar,
      toggleMobileRightSidebar: this.toggleMobileRightSidebar,
      handleSetPageRightSidebar: this.handleSetPageRightSidebar,

      handleSetBodyWhiteBg: this.handleSetBodyWhiteBg,
      handleSetPageBoxedLayout: this.handleSetPageBoxedLayout,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 0) {
      this.setState(() => ({
        hasScroll: true,
      }));
    } else {
      this.setState(() => ({
        hasScroll: false,
      }));
    }
    const elm = document.getElementsByClassName('nvtooltip');
    for (let i = 0; i < elm.length; i++) {
      elm[i].classList.add('d-none');
    }
  };

  render() {
    const {
      pageHeader,
      pageSidebar,
      pageRightSidebar,
      pageSidebarWide,
      pageSidebarLight,
      pageSidebarMinify,
      pageSidebarToggled,
      pageTopMenu,
      pageContentFullHeight,
      pageTwoSidebar,
      pageRightSidebarCollapsed,
      pageMobileRightSidebarToggled,
      hasScroll,
      pageContent,
      pageFooter,
    } = this.state;

    return (
      <PageSettings.Provider value={this.state}>
        <AppContextsProvider>
          <div
            className={`fade page-sidebar-fixed show page-container ${pageHeader ? 'page-header-fixed ' : ''}${
              pageSidebar ? '' : 'page-without-sidebar '
            }${pageRightSidebar ? 'page-with-right-sidebar ' : ''}${pageSidebarWide ? 'page-with-wide-sidebar ' : ''}${
              pageSidebarLight ? 'page-with-light-sidebar ' : ''
            }${pageSidebarMinify ? 'page-sidebar-minified ' : ''}${pageSidebarToggled ? 'page-sidebar-toggled ' : ''}${
              pageTopMenu ? 'page-with-top-menu ' : ''
            }${pageContentFullHeight ? 'page-content-full-height ' : ''}${
              pageTwoSidebar ? 'page-with-two-sidebar ' : ''
            }${pageRightSidebarCollapsed ? 'page-right-sidebar-collapsed ' : ''}${
              pageMobileRightSidebarToggled ? 'page-right-sidebar-toggled ' : ''
            }${hasScroll ? 'has-scroll ' : ''}`}
          >
            {pageHeader && (
              <Header
                className={classNames({
                  'page-sidebar-minified-mobile-margin': pageSidebarMinify && pageSidebarToggled,
                })}
              />
            )}
            {pageSidebar && <Sidebar />}
            {pageTwoSidebar && <SidebarRight />}
            {pageTopMenu && <TopMenu />}
            {pageContent && (
              <Content
                className={classNames({
                  'page-sidebar-minified-mobile-margin': pageSidebarMinify && pageSidebarToggled,
                })}
              />
            )}
            {pageFooter && <Footer />}
            <FloatSubMenu />
          </div>
        </AppContextsProvider>
      </PageSettings.Provider>
    );
  }
}

export default App;
