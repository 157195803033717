// ----------------URL PATHS----------------//

export const urlPaths = {
  home: "/",
  login: "/login",
  details: "/details",
  cardLogs: "/card-logs",
  events: "/events",
  accounts: "/accounts",
  profile: "/profile",
  withdrawals: "/withdrawals",
  deposits: "/deposits",
  terminals: "/terminals",
  notifications: "/notifications",
  otherEvents: "/other-events",
};

export const urlPathsWithParams = {
  details: `${urlPaths.details}/:id?`,
  events: `${urlPaths.events}/:id?`,
  profile: `${urlPaths.profile}/:id?/:section?`,
};
