import React, { useEffect, useState } from 'react';
import Button from "react-bootstrap/Button"
import classNames from 'classnames';
import Spinner from "react-bootstrap/Spinner";
import "./CustomLoader.scss";

/**
 * Use to show progress wheel for loading UI without knowing actual time the user has to wait
 *
 * @category Component
 * @module CustomLoader
 *
 * @typedef {Object} CustomLoaderProps
 * @property {Object<string, *>} classes
 * @property {string} [customClass]
 * @property {string} [customIconClass]
 * @property {string} [customMessageClass]
 * @property {boolean} isLoading
 * @property {string | React.ReactNode} [message]
 * @property {string} [size]
 * @property {string} [color]
 * @property {string} [retryText]
 * @property {function} [retry]
 * @property {string} [retryButtonText]
 * @property {Object} [retryButtonProps]
 * @property {Object} [retryTextProps]
 * @property {number} [timeout]
 * @property {function} [onTimeout] - callback method on timeout
 * @param {CustomLoaderProps} props
 */
const CustomLoader = ({
  customClass,
  customMessageClass,
  isLoading,
  message = 'Loading, please wait',
  retryText = 'An operation is taking longer than expected',
  retry = () => window.location.reload(),
  retryButtonText = 'Retry',
  retryButtonProps = {},
  retryTextProps = {},
  timeout = 10,
  onTimeout,
}) => {
  const [timer, setTimer] = useState();
  const [showRetry, setShowRetry] = useState(false);

  useEffect(() => {
    let timeoutId = null;
    if (isLoading && !timer) {
      timeoutId = setTimeout(() => setShowRetry(true), timeout * 1000);
      setTimer(timeoutId);
    }
    if (!isLoading && timer) {
      clearTimeout(timer);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isLoading, timer, timeout]);

  useEffect(() => {
    if (showRetry) {
      clearTimeout(timer);
      if (onTimeout) onTimeout();
    }
  }, [showRetry, timer, onTimeout]);

  if (!isLoading) return null;

  return (
    <div
      className={classNames({
        'CustomLoader': !customClass,
        [customClass]: customClass,
      })}
    >
      {!showRetry && (
        <Spinner animation="border" as="div">
          <span className="sr-only">Loading</span>
        </Spinner>
      )}
      {!showRetry && message && (
        <div
          className={classNames({
            'CustomLoader-messageBox': !customMessageClass,
            [customMessageClass]: customMessageClass,
          })}
          style={{ fontSize: 16 }}
        /**
         * font size set to fixed 16 pixel to prevent
         * loading text changing font size while loading
         */
        >
          {message}
        </div>
      )}
      {showRetry && (
        <>
          <h5 variant="subtitle1" style={{ marginBottom: 24 }} {...retryTextProps}>
            {retryText}
          </h5>
          <Button
            onClick={(e) => {
              setShowRetry(false);
              setTimer(null);
              retry(e);
            }}
            {...retryButtonProps}
          >
            {retryButtonText}
          </Button>
        </>
      )}
    </div>
  );
};

export default CustomLoader;
