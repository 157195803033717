import React, { useState, useCallback } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useAuth } from "../../../Auth";
import UserAvatar from "../../UserAvatar";

const DropdownProfile = () => {
  const { user, logout } = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleToggle = useCallback(() => {
    setDropdownOpen(!dropdownOpen);
  }, [dropdownOpen, setDropdownOpen]);

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={handleToggle}
      className="dropdown navbar-user"
      tag="li"
    >
      <DropdownToggle tag="a">
        <UserAvatar name={user} size={32} />
        <span className="ml-2 d-none d-md-inline">{user}</span>{" "}
        <b className="caret" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
        {/* <DropdownItem>Edit Profile</DropdownItem> */}
        {/* <DropdownItem>
        <span className="badge badge-danger pull-right">2</span>
        {' '}
        Inbox
      </DropdownItem> */}
        {/* <DropdownItem>Calendar</DropdownItem> */}
        <DropdownItem>Setting</DropdownItem>
        <div className="dropdown-divider" />
        <DropdownItem onClick={logout}>Log Out</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default DropdownProfile;
