import queryString from "query-string";
import axios from "axios";
import defaultTo from "lodash/defaultTo";
import get from "lodash/get";
import noop from "lodash/noop";
import insertItemsIf from "@dynameyes/js-utils/insertItemsIf";
import { useEffect, useState } from "react";
import { getAuthFromStorage } from "../contexts/contextAuth";

export const restProxy = process.env.REACT_APP_PROXY_REST;

export const attachRestProxy = (url) => {
  if (!restProxy) return url;

  const parsed = queryString.parseUrl(url);

  return queryString.stringifyUrl({
    url: restProxy,
    query: {
      ...parsed.query,
      url: decodeURIComponent(parsed.url),
    },
  });
};

export const GET = async (url) => {
  try {
    const response = await axios({
      url: attachRestProxy(url),
      method: "GET",
    });
    return get(response, "data");
  } catch (error) {
    console.log("error:GET", error);
    return undefined;
  }
};

export const POST = (url, body, onError = noop) =>
  new Promise((resolve, reject) => {
    axios({
      url,
      method: "POST",
      data: body,
    })
      .then((response) => {
        resolve(get(response, "data"));
      })
      .catch((error) => {
        console.log("error:POST", error);

        const onErrorDataResponse = defaultTo(
          get(error, "response.data"),
          error
        );
        onError(onErrorDataResponse);
        reject(error);
      });
  });

/**
 * Secured http GET by Authorization header
 * @param {*} url
 * @returns
 */
export const SECURED_GET = async (url) => {
  const auth = getAuthFromStorage();

  try {
    const response = await axios({
      url: attachRestProxy(url),
      method: "GET",
      headers: {
        ...insertItemsIf(get(auth, "token"), {
          Authorization: `Token ${get(auth, "token")}`,
        }),
      },
    });
    return get(response, "data");
  } catch (error) {
    console.log("error:GET", error);
    return undefined;
  }
};

/**
 * Secured http POST by Authorization header
 * @param {*} url
 * @param {*} body
 * @param {*} onError
 * @returns
 */
export const SECURED_POST = (url, body, onError = noop) => {
  const auth = getAuthFromStorage();

  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "POST",
      headers: {
        ...insertItemsIf(get(auth, "token"), {
          Authorization: `Token ${get(auth, "token")}`,
        }),
      },
      data: body,
    })
      .then((response) => {
        resolve(get(response, "data"));
      })
      .catch((error) => {
        console.log("error:POST", error);

        const onErrorDataResponse = defaultTo(
          get(error, "response.data"),
          error
        );
        onError(onErrorDataResponse);
        reject(error);
      });
  });
};

export const useGetSecured = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (url) {
      setLoading(true);
      SECURED_GET(url)
        .then((response) => setData(response))
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [url]);

  return {
    data,
    loading,
    error,
  };
};
