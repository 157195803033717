import React from "react";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import styled from "styled-components";
import { PageSettings } from "../../config/page-settings.js";
import SidebarProfile from "./sidebarProfile";
import SidebarNav from "./SidebarNav";
import variables from "../../scss/_variables.scss";

const StyledSidebar = styled("div")(() => ({
  color: variables.primaryTextColor,
  background: variables.sidebarBackground,

  ".nav": {
    // main menu
    "& a": {
      color: variables.primaryTextColor,
    },

    "& a i.fa": {
      color: variables.primaryLightTextColor,
    },

    "& li.active > a": {
      background: variables.contentBackground,

      "& > i": {
        color: variables.primaryAccentColor,
      },
    },

    "& > li.nav-profile .info": {
      color: variables.primaryTextColor,
    },

    // Sub menu
    "& .sub-menu > li.active": {
      "& a": {
        // reset background to only set white if root menu
        background: variables.sidebarBackground,
      },

      "&:after": {
        borderColor: variables.primaryAccentColor,
      },
    },

    // menu badge
    "& li > a .badge": {
      background: variables.primaryAccentColor,
    },
  },

  ".sidebar-minify-btn": {
    background: variables.white,
    padding: "8px 20px 8px 10px",
    marginTop: "8rem",

    "&:hover, &:focus": {
      background: variables.contentBackground,
    },

    "& i": {
      fontSize: 16,
      margin: 0,
      color: variables.black,
    },
  },
}));

class Sidebar extends React.Component {
  static contextType = PageSettings;

  render() {
    return (
      <PageSettings.Consumer>
        {({
          toggleSidebarMinify,
          toggleMobileSidebar,
          pageSidebarTransparent,
        }) => (
          <>
            <StyledSidebar
              id="sidebar"
              className={`sidebar ${
                pageSidebarTransparent ? "sidebar-transparent" : ""
              }`}
            >
              <PerfectScrollbar
                className="height-full"
                options={{ suppressScrollX: true }}
              >
                {!this.context.pageSidebarSearch && <SidebarProfile />}
                <SidebarNav />
                <Link
                  to="/"
                  className="sidebar-minify-btn"
                  onClick={toggleSidebarMinify}
                >
                  <i className="fa fa-angle-double-left" />
                </Link>
              </PerfectScrollbar>
            </StyledSidebar>
            <div className="sidebar-bg" />
            <div
              className="sidebar-mobile-dismiss"
              onClick={toggleMobileSidebar}
            />
          </>
        )}
      </PageSettings.Consumer>
    );
  }
}

export default Sidebar;
