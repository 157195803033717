import LogRocket from 'logrocket';
import ReactGA from 'react-ga';
import { useContext } from 'react';
import { setCookie } from '@dynameyes/js-utils/';
import { GoogleAnalyticsContext } from './contexts';

const logRocketId = process.env.REACT_APP_LOG_ROCKET_ID;
const gUaId = process.env.REACT_APP_GOOGLE_UA_ID;

export const logRocketStartNewSession = () => {
  if (logRocketId) {
    LogRocket.startNewSession();
  }
};

export const logRocketInit = () => {
  if (logRocketId) {
    LogRocket.init(logRocketId, {
      release: `v${process.env.REACT_APP_VERSION}`,
    });

    LogRocket.getSessionURL((sessionURL) => {
      setCookie('logrocket_URL', sessionURL, 1);
    });

    window.getLogRocketUrl = () => {
      LogRocket.getSessionURL((sessionURL) => {
        console.warn('command is to be used only by QA or DEV.', sessionURL);
      });
    };
  }
};

export const useGoogleAnalytics = () => {
  const { gaInitialized, setGaInitialized } = useContext(GoogleAnalyticsContext);

  const initGoogleAnalytics = () => {
    if (gUaId) {
      ReactGA.initialize(gUaId);
      setGaInitialized(true);
    }
  };

  const gaPageView = (path) => {
    if (gaInitialized) {
      ReactGA.pageview(path);
    }
  };

  const gaEvent = (category, action, otherParams) => {
    if (gaInitialized) {
      ReactGA.event({
        category,
        action,
        ...otherParams,
      });
    }
  };

  return {
    initGoogleAnalytics,
    gaPageView,
    gaEvent,
  };
};
