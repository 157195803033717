import React from "react";

export const LanguageContext = React.createContext({
  currentLanguage:
    window.localStorage.getItem("language") ||
    process.env.REACT_APP_DEFAULT_LANGUAGE ||
    "en",
  setCurrentLanguage: () => {},
});
LanguageContext.displayName = "CurrentLanguage";
