import { urlPaths } from "../../router/urls";

const Menu = [
  { path: urlPaths.home, icon: "fa fa-map", title: "Map" },
  {
    path: urlPaths.terminals,
    icon: "fa fa-desktop",
    title: "Machines",
    children: [
      { path: "/#", title: "Loading" }, // place holder loading
    ],
  },
  // { path: urlPaths.accounts, icon: 'fa fa-users', title: 'Accounts' },
  {
    path: urlPaths.withdrawals,
    icon: "fa fa-credit-card",
    title: "Withdrawals",
  },
  { path: urlPaths.deposits, icon: "fa fa-plus", title: "Deposits" },
  { path: urlPaths.notifications, icon: "fa fa-bell", title: "Notifications" },
  { path: urlPaths.otherEvents, icon: "fa fa-bullhorn", title: "Other Events" },
  // { path: '#', icon: 'fa fa-list', title: 'Payment Orders' },
  // { path: '#', icon: 'fa fa-archive', title: 'Archive' },
  // { path: '#', icon: 'fa fa-fa-hdd', title: 'Email', badge: '10' },
];

export default Menu;
