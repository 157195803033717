export const BASE_ENDPOINT = process.env.REACT_APP_API_URL;
export const BASE_ATM_ENDPOINT = process.env.REACT_APP_ATM_API_URL;

export const ENDPOINTS = {
  LOGIN: `${BASE_ENDPOINT}/users/login/`,
  TERMINALS: `${BASE_ENDPOINT}/api/terminals/`,
  TERMINALS_WITH_DETAILS: `${BASE_ENDPOINT}/api/terminals-details/`,
  SENSORS: `${BASE_ENDPOINT}/api/sensors`,
  NOTIFICATIONS: `${BASE_ENDPOINT}/api/notifications/`,
  DEPOSITS: `${BASE_ENDPOINT}/api/deposits/`,
  WITHDRAWS: `${BASE_ENDPOINT}/api/withdraws/`,
  OTHER_EVENTS: `${BASE_ENDPOINT}/api/other-events/`,
};

export const ATM_ENDPOINTS = {
  CARD_REQUEST_LOGS: `${BASE_ATM_ENDPOINT}/card/logs`,
  CARD_REQUEST_DETAILS: `${BASE_ATM_ENDPOINT}/card/logs`,
  EVENTS: `${BASE_ATM_ENDPOINT}/events`,
  GET_OPERATION_STATUS_OPTIONS: `${BASE_ATM_ENDPOINT}/terminal/operation-status/`,
  SET_OPERATION_STATUS: `${BASE_ATM_ENDPOINT}/terminal/operation-status`,
  SET_NOTIFICATION_READ: `${BASE_ATM_ENDPOINT}/notifications/`,
};
