import { each, isObject } from "lodash";
import React, { useContext } from "react";
import queryString from "query-string";

const CacheContext = React.createContext({
  globalCache: {},
  setCache: (id, value, expirySecs) => {},
  getCache: (id, fallbackValue) => {},
});

export const useCacheContext = () => {
  return useContext(CacheContext);
}; // to be called in components

export const DEFAULT_EXPIRY_IN_SECONDS = 600; // 600 seconds = 10 mins

export const urlToHashId = (url, uniqueIdentifiers) => {
  try {
    return queryString.stringifyUrl({ url, query: uniqueIdentifiers });
  } catch (error) {
    let identifiers = "";
    // only use non-object from request for id
    each(uniqueIdentifiers, (item, key) => {
      if (!isObject(item)) {
        identifiers += `${key}=${item}`;
      }
    });
    return `${url}?${identifiers}`;
  }
};

/**
 * To be used in api requests
 */
export const CacheProvider = ({ children }) => {
  const [globalCache, setGlobalCache] = React.useState({});

  const setCache = (id, value, expirySecs = 0) => {
    setGlobalCache((prevState) => ({
      ...prevState,
      [id]: {
        value,
        expiry: Date.now() + expirySecs * 1000,
      },
    }));
  };

  const getCache = (id, defaultValue) => {
    const cacheItem = globalCache[id];
    if (!cacheItem) {
      return defaultValue;
    }

    /**
     * Remove from cache if expired
     */
    if (cacheItem.expiry && cacheItem.expiry - Date.now() < 1) {
      setGlobalCache((prevState) => ({
        ...prevState,
        [id]: undefined,
      }));
      return defaultValue;
    }
    return cacheItem.value || defaultValue;
  };

  return (
    <CacheContext.Provider
      value={{
        globalCache,
        setCache,
        getCache,
      }}
    >
      {children}
    </CacheContext.Provider>
  );
};

export default CacheContext;
